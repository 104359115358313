import React from 'react';

export default function NasaLogo() {
  return (
    <svg
      width={312}
      height={258}
      viewBox='0 0 312 258'
      style={{
        fillRule: 'evenodd',
        clipRule: 'evenodd',
        strokeLinejoin: 'round',
        strokeMiterlimit: 2
      }}
    >
      <title>NASA</title>
      <g id='nasa-logo-pos'>
        <path
          d='M273.427,387C273.427,457.761 215.188,516 144.427,516C73.665,516 15.427,457.761 15.427,387C15.427,316.239 73.665,258 144.427,258C215.188,258 273.427,316.239 273.427,387Z'
          style={{ fill: 'rgb(0,102,179)', fillRule: 'nonzero' }}
        />
        <path
          d='M146.205,269.721C146.205,270.297 145.731,270.771 145.154,270.771C144.578,270.771 144.104,270.297 144.104,269.721C144.104,269.145 144.578,268.67 145.154,268.67C145.731,268.67 146.205,269.145 146.205,269.721Z'
          style={{ fill: 'white', fillRule: 'nonzero' }}
        />
        <path
          d='M153.48,285.643C153.48,286.353 152.896,286.936 152.187,286.936C151.477,286.936 150.893,286.353 150.893,285.643C150.893,284.934 151.477,284.35 152.187,284.35C152.896,284.35 153.48,284.934 153.48,285.643Z'
          style={{ fill: 'white', fillRule: 'nonzero' }}
        />
        <path
          d='M172.393,311.67C172.393,312.379 171.81,312.963 171.1,312.963C170.39,312.963 169.807,312.379 169.807,311.67C169.807,310.959 170.39,310.376 171.1,310.376C171.81,310.376 172.393,310.959 172.393,311.67Z'
          style={{ fill: 'white', fillRule: 'nonzero' }}
        />
        <path
          d='M142.002,294.615C142.002,295.191 141.528,295.666 140.951,295.666C140.375,295.666 139.901,295.191 139.901,294.615C139.901,294.039 140.375,293.564 140.951,293.564C141.528,293.564 142.002,294.039 142.002,294.615Z'
          style={{ fill: 'white', fillRule: 'nonzero' }}
        />
        <path
          d='M153.318,300.596C153.318,301.172 152.843,301.647 152.267,301.647C151.691,301.647 151.217,301.172 151.217,300.596C151.217,300.02 151.691,299.545 152.267,299.545C152.843,299.545 153.318,300.02 153.318,300.596Z'
          style={{ fill: 'white', fillRule: 'nonzero' }}
        />
        <path
          d='M138.607,283.865C138.607,284.574 138.023,285.158 137.314,285.158C136.604,285.158 136.021,284.574 136.021,283.865C136.021,283.156 136.604,282.572 137.314,282.572C138.023,282.572 138.607,283.156 138.607,283.865Z'
          style={{ fill: 'white', fillRule: 'nonzero' }}
        />
        <path
          d='M121.472,294.292C121.472,294.869 120.998,295.343 120.422,295.343C119.845,295.343 119.371,294.869 119.371,294.292C119.371,293.716 119.845,293.241 120.422,293.241C120.998,293.241 121.472,293.716 121.472,294.292Z'
          style={{ fill: 'white', fillRule: 'nonzero' }}
        />
        <path
          d='M124.544,296.394C124.544,296.97 124.069,297.444 123.493,297.444C122.917,297.444 122.442,296.97 122.442,296.394C122.442,295.817 122.917,295.343 123.493,295.343C124.069,295.343 124.544,295.817 124.544,296.394Z'
          style={{ fill: 'white', fillRule: 'nonzero' }}
        />
        <path
          d='M127.615,298.495C127.615,299.071 127.14,299.545 126.564,299.545C125.987,299.545 125.513,299.071 125.513,298.495C125.513,297.918 125.987,297.444 126.564,297.444C127.14,297.444 127.615,297.918 127.615,298.495Z'
          style={{ fill: 'white', fillRule: 'nonzero' }}
        />
        <path
          d='M122.927,302.536C122.927,303.112 122.453,303.587 121.877,303.587C121.3,303.587 120.826,303.112 120.826,302.536C120.826,301.959 121.3,301.485 121.877,301.485C122.453,301.485 122.927,301.959 122.927,302.536Z'
          style={{ fill: 'white', fillRule: 'nonzero' }}
        />
        <path
          d='M98.517,319.671C98.517,320.248 98.043,320.722 97.466,320.722C96.89,320.722 96.415,320.248 96.415,319.671C96.415,319.095 96.89,318.621 97.466,318.621C98.043,318.621 98.517,319.095 98.517,319.671Z'
          style={{ fill: 'white', fillRule: 'nonzero' }}
        />
        <path
          d='M103.044,325.168C103.044,325.744 102.569,326.218 101.993,326.218C101.417,326.218 100.942,325.744 100.942,325.168C100.942,324.591 101.417,324.117 101.993,324.117C102.569,324.117 103.044,324.591 103.044,325.168Z'
          style={{ fill: 'white', fillRule: 'nonzero' }}
        />
        <path
          d='M99.487,413.915C99.487,414.491 99.012,414.966 98.436,414.966C97.86,414.966 97.385,414.491 97.385,413.915C97.385,413.339 97.86,412.864 98.436,412.864C99.012,412.864 99.487,413.339 99.487,413.915Z'
          style={{ fill: 'white', fillRule: 'nonzero' }}
        />
        <path
          d='M100.134,438.404C100.134,439.115 99.549,439.699 98.84,439.699C98.131,439.699 97.547,439.115 97.547,438.404C97.547,437.696 98.131,437.113 98.84,437.113C99.549,437.113 100.134,437.696 100.134,438.404Z'
          style={{ fill: 'white', fillRule: 'nonzero' }}
        />
        <path
          d='M86.231,450.935C86.231,451.511 85.756,451.986 85.18,451.986C84.604,451.986 84.13,451.511 84.13,450.935C84.13,450.357 84.604,449.884 85.18,449.884C85.756,449.884 86.231,450.357 86.231,450.935Z'
          style={{ fill: 'white', fillRule: 'nonzero' }}
        />
        <path
          d='M69.58,456.027C69.58,456.736 68.996,457.32 68.287,457.32C67.578,457.32 66.994,456.736 66.994,456.027C66.994,455.316 67.578,454.732 68.287,454.732C68.996,454.732 69.58,455.316 69.58,456.027Z'
          style={{ fill: 'white', fillRule: 'nonzero' }}
        />
        <path
          d='M66.186,444.71C66.186,445.419 65.602,446.003 64.893,446.003C64.183,446.003 63.599,445.419 63.599,444.71C63.599,444.001 64.183,443.417 64.893,443.417C65.602,443.417 66.186,444.001 66.186,444.71Z'
          style={{ fill: 'white', fillRule: 'nonzero' }}
        />
        <path
          d='M64.246,451.904C64.246,452.48 63.772,452.954 63.195,452.954C62.619,452.954 62.145,452.48 62.145,451.904C62.145,451.327 62.619,450.853 63.195,450.853C63.772,450.853 64.246,451.327 64.246,451.904Z'
          style={{ fill: 'white', fillRule: 'nonzero' }}
        />
        <path
          d='M51.798,445.923C51.798,446.499 51.324,446.974 50.747,446.974C50.171,446.974 49.697,446.499 49.697,445.923C49.697,445.347 50.171,444.872 50.747,444.872C51.324,444.872 51.798,445.347 51.798,445.923Z'
          style={{ fill: 'white', fillRule: 'nonzero' }}
        />
        <path
          d='M95.283,466.129C95.283,466.705 94.809,467.18 94.233,467.18C93.656,467.18 93.182,466.705 93.182,466.129C93.182,465.553 93.656,465.078 94.233,465.078C94.809,465.078 95.283,465.553 95.283,466.129Z'
          style={{ fill: 'white', fillRule: 'nonzero' }}
        />
        <path
          d='M108.378,456.511C108.378,457.22 107.794,457.804 107.085,457.804C106.376,457.804 105.792,457.22 105.792,456.511C105.792,455.802 106.376,455.218 107.085,455.218C107.794,455.218 108.378,455.802 108.378,456.511Z'
          style={{ fill: 'white', fillRule: 'nonzero' }}
        />
        <path
          d='M109.833,468.877C109.833,469.453 109.359,469.928 108.782,469.928C108.206,469.928 107.731,469.453 107.731,468.877C107.731,468.301 108.206,467.828 108.782,467.828C109.359,467.828 109.833,468.301 109.833,468.877Z'
          style={{ fill: 'white', fillRule: 'nonzero' }}
        />
        <path
          d='M163.664,457.077C163.664,457.654 163.19,458.128 162.613,458.128C162.037,458.128 161.563,457.654 161.563,457.077C161.563,456.499 162.037,456.027 162.613,456.027C163.19,456.027 163.664,456.499 163.664,457.077Z'
          style={{ fill: 'white', fillRule: 'nonzero' }}
        />
        <path
          d='M138.607,465.967C138.607,466.545 138.132,467.018 137.556,467.018C136.98,467.018 136.506,466.545 136.506,465.967C136.506,465.391 136.98,464.918 137.556,464.918C138.132,464.918 138.607,465.391 138.607,465.967Z'
          style={{ fill: 'white', fillRule: 'nonzero' }}
        />
        <path
          d='M206.017,478.739C206.017,479.315 205.543,479.789 204.966,479.789C204.39,479.789 203.915,479.315 203.915,478.739C203.915,478.162 204.39,477.688 204.966,477.688C205.543,477.688 206.017,478.162 206.017,478.739Z'
          style={{ fill: 'white', fillRule: 'nonzero' }}
        />
        <path
          d='M211.513,429.029C211.513,429.738 210.929,430.322 210.22,430.322C209.511,430.322 208.927,429.738 208.927,429.029C208.927,428.321 209.511,427.737 210.22,427.737C210.929,427.737 211.513,428.321 211.513,429.029Z'
          style={{ fill: 'white', fillRule: 'nonzero' }}
        />
        <path
          d='M201.167,436.061C201.167,436.639 200.693,437.113 200.116,437.113C199.54,437.113 199.066,436.639 199.066,436.061C199.066,435.487 199.54,435.012 200.116,435.012C200.693,435.012 201.167,435.487 201.167,436.061Z'
          style={{ fill: 'white', fillRule: 'nonzero' }}
        />
        <path
          d='M211.352,438.082C211.352,438.791 210.768,439.375 210.059,439.375C209.35,439.375 208.766,438.791 208.766,438.082C208.766,437.373 209.35,436.789 210.059,436.789C210.768,436.789 211.352,437.373 211.352,438.082Z'
          style={{ fill: 'white', fillRule: 'nonzero' }}
        />
        <path
          d='M201.167,450.611C201.167,451.187 200.693,451.661 200.116,451.661C199.54,451.661 199.066,451.187 199.066,450.611C199.066,450.034 199.54,449.56 200.116,449.56C200.693,449.56 201.167,450.034 201.167,450.611Z'
          style={{ fill: 'white', fillRule: 'nonzero' }}
        />
        <path
          d='M227.517,421.998C227.517,422.574 227.043,423.049 226.467,423.049C225.889,423.049 225.414,422.574 225.414,421.998C225.414,421.422 225.889,420.947 226.467,420.947C227.043,420.947 227.517,421.422 227.517,421.998Z'
          style={{ fill: 'white', fillRule: 'nonzero' }}
        />
        <path
          d='M230.104,428.383C230.104,429.094 229.52,429.676 228.811,429.676C228.101,429.676 227.517,429.094 227.517,428.383C227.517,427.674 228.101,427.09 228.811,427.09C229.52,427.09 230.104,427.674 230.104,428.383Z'
          style={{ fill: 'white', fillRule: 'nonzero' }}
        />
        <path
          d='M248.209,435.981C248.209,436.69 247.625,437.274 246.915,437.274C246.205,437.274 245.621,436.69 245.621,435.981C245.621,435.272 246.205,434.688 246.915,434.688C247.625,434.688 248.209,435.272 248.209,435.981Z'
          style={{ fill: 'white', fillRule: 'nonzero' }}
        />
        <path
          d='M229.781,449.48C229.781,450.056 229.306,450.53 228.73,450.53C228.154,450.53 227.679,450.056 227.679,449.48C227.679,448.904 228.154,448.429 228.73,448.429C229.306,448.429 229.781,448.904 229.781,449.48Z'
          style={{ fill: 'white', fillRule: 'nonzero' }}
        />
        <path
          d='M241.419,438.487C241.419,439.063 240.945,439.537 240.368,439.537C239.792,439.537 239.318,439.063 239.318,438.487C239.318,437.91 239.792,437.436 240.368,437.436C240.945,437.436 241.419,437.91 241.419,438.487Z'
          style={{ fill: 'white', fillRule: 'nonzero' }}
        />
        <path
          d='M239.318,455.46C239.318,456.036 238.843,456.511 238.267,456.511C237.691,456.511 237.216,456.036 237.216,455.46C237.216,454.884 237.691,454.409 238.267,454.409C238.843,454.409 239.318,454.884 239.318,455.46Z'
          style={{ fill: 'white', fillRule: 'nonzero' }}
        />
        <path
          d='M168.848,282.647C169.026,281.99 169.558,281.478 170.227,281.329L170.594,278.562L171.137,281.355C171.785,281.54 172.287,282.07 172.434,282.733L175.235,283.13L172.408,283.628C172.23,284.267 171.717,284.765 171.07,284.923L170.594,287.77L170.114,284.902C169.485,284.72 168.993,284.21 168.837,283.569L166.135,283.13L168.848,282.647Z'
          style={{ fill: 'white', fillRule: 'nonzero' }}
        />
        <path
          d='M96.37,480.946C96.548,480.289 97.081,479.776 97.749,479.627L98.117,476.862L98.661,479.655C99.308,479.838 99.811,480.367 99.956,481.031L102.758,481.428L99.931,481.926C99.753,482.565 99.239,483.063 98.592,483.221L98.117,486.07L97.637,483.201C97.007,483.018 96.516,482.508 96.359,481.867L93.657,481.428L96.37,480.946Z'
          style={{ fill: 'white', fillRule: 'nonzero' }}
        />
        <path
          d='M229.659,455.38C229.837,454.722 230.368,454.21 231.037,454.062L231.405,451.294L231.949,454.087C232.596,454.271 233.099,454.802 233.244,455.466L236.045,455.863L233.219,456.361C233.04,456.999 232.528,457.497 231.88,457.655L231.405,460.503L230.925,457.634C230.295,457.452 229.805,456.941 229.648,456.302L226.947,455.863L229.659,455.38Z'
          style={{ fill: 'white', fillRule: 'nonzero' }}
        />
        <path
          d='M240.211,426.869C240.388,426.211 240.92,425.699 241.588,425.551L241.956,422.783L242.5,425.576C243.148,425.762 243.651,426.291 243.796,426.955L246.597,427.352L243.771,427.85C243.592,428.488 243.079,428.987 242.433,429.145L241.956,431.992L241.477,429.123C240.847,428.942 240.357,428.432 240.2,427.791L237.497,427.352L240.211,426.869Z'
          style={{ fill: 'white', fillRule: 'nonzero' }}
        />
        <path
          d='M109.303,321.556C109.481,320.898 110.013,320.387 110.681,320.237L111.049,317.471L111.593,320.264C112.241,320.448 112.743,320.978 112.889,321.642L115.69,322.039L112.863,322.536C112.685,323.175 112.172,323.674 111.525,323.832L111.049,326.679L110.569,323.811C109.94,323.628 109.449,323.118 109.293,322.478L106.59,322.039L109.303,321.556Z'
          style={{ fill: 'white', fillRule: 'nonzero' }}
        />
        <path
          d='M100.756,381.578C110.286,398.228 121.393,410.411 130.341,421.988C138.283,432.264 153.757,437.408 148.307,442.286C142.164,447.782 127.292,423.533 127.292,423.533C117.408,411.614 107.949,398.97 98.299,382.433L100.756,381.578Z'
          style={{ fill: 'white', fillRule: 'nonzero' }}
        />
        <path
          d='M0,425.268C46.046,395.026 126.409,375.502 192.597,349.225C242.884,329.26 287.601,304.198 311.639,269.689C250.721,393.777 81.066,379.089 3.312,426.424L0,425.268Z'
          style={{ fill: 'rgb(237,28,36)', fillRule: 'nonzero' }}
        />
        <path
          d='M107.05,363.738L130.11,363.738L146.644,405.374L152.155,410.905L122.134,410.905L127.645,405.374L125.687,400.294L109.117,400.294L107.413,406.103L111.365,410.905L95.701,410.905L101.213,405.374L111.837,368.554L107.05,363.738ZM122.751,393.004L116.078,376.098L111.184,393.004L122.751,393.004Z'
          style={{ fill: 'white', fillRule: 'nonzero' }}
        />
        <path
          d='M214.184,363.738L238.118,363.738L254.653,405.374L260.164,410.905L230.143,410.905L235.654,405.374L233.696,400.294L217.125,400.294L215.421,406.103L219.373,410.905L203.71,410.905L209.222,405.374L219.845,368.554L214.184,363.738ZM230.759,393.004L224.087,376.098L219.192,393.004L230.759,393.004Z'
          style={{ fill: 'white', fillRule: 'nonzero' }}
        />
        <path
          d='M75.758,393.113L75.758,368.554L70.972,363.738L86.925,363.738L82.14,368.554L82.14,405.374L87.651,410.905L66.693,410.905L48.637,380.361L48.637,405.374L53.277,410.905L36.6,410.905L42.111,405.374L42.111,368.554L37.325,363.738L58.21,363.738L75.758,393.113Z'
          style={{ fill: 'white', fillRule: 'nonzero' }}
        />
        <path
          d='M196.553,375.835L196.063,361.15L191.12,364.562C184.156,362.127 176.051,362.219 170.364,364.91C165.071,367.415 159.36,372.704 160.055,380.22C160.752,387.736 166.577,390.645 171.826,393.024C178.582,396.086 185.936,397.121 185.827,401.167C185.757,403.741 181.485,405.587 178.165,405.482C175.135,405.386 165.28,404.646 160.125,398.523L161.101,413.97L166.604,409.935C173.289,412.3 181.392,413.292 188.889,410.847C196.341,408.419 204.174,402.183 199.827,391.285C197.956,386.596 192.804,383.705 189.731,382.447C186.917,381.295 178.333,377.92 175.936,376.671C173.136,375.212 173.448,370.582 180.185,369.642C186.288,368.791 194.742,373.678 196.553,375.835Z'
          style={{ fill: 'white', fillRule: 'nonzero' }}
        />
        <path
          d='M35.658,497.677C128.131,403.298 265.971,359.693 311.639,269.689C284.138,357.193 136.85,405.454 35.408,502.115L35.658,497.677Z'
          style={{ fill: 'rgb(237,28,36)', fillRule: 'nonzero' }}
        />
        <path
          d='M98.299,382.433C97.216,380.577 96.131,378.672 95.042,376.714C70.388,332.361 73.944,287.098 118.563,306.82C156.431,323.559 192.6,370.511 207.146,403.857C213.887,419.307 231.96,479.946 173.764,455.374L173.65,455.019C228.613,477.651 219.111,420.301 189.691,376.331C173.042,351.45 151.331,330.725 137.637,320.722C92.05,287.421 64.246,307.79 96.253,373.097C97.702,376.053 99.208,378.875 100.756,381.578L98.299,382.433Z'
          style={{ fill: 'white', fillRule: 'nonzero' }}
        />
      </g>
      <g id='nasa-logo-neg-mono'>
        <path
          d='M273.427,129.05C273.427,199.811 215.188,258.05 144.427,258.05C73.665,258.05 15.427,199.811 15.427,129.05C15.427,58.289 73.665,0.05 144.427,0.05C215.188,0.05 273.427,58.289 273.427,129.05ZM263.427,129.05C263.427,194.326 209.703,248.05 144.427,248.05C79.151,248.05 25.427,194.326 25.427,129.05C25.427,63.774 79.151,10.05 144.427,10.05C209.703,10.05 263.427,63.774 263.427,129.05Z'
          style={{ fill: 'white' }}
        />
        <path
          d='M146.205,11.771C146.205,12.347 145.731,12.821 145.154,12.821C144.578,12.821 144.104,12.347 144.104,11.771C144.104,11.195 144.578,10.719 145.154,10.719C145.731,10.719 146.205,11.195 146.205,11.771Z'
          style={{ fill: 'white', fillRule: 'nonzero' }}
        />
        <path
          d='M153.48,27.693C153.48,28.403 152.896,28.986 152.187,28.986C151.477,28.986 150.893,28.403 150.893,27.693C150.893,26.984 151.477,26.4 152.187,26.4C152.896,26.4 153.48,26.984 153.48,27.693Z'
          style={{ fill: 'white', fillRule: 'nonzero' }}
        />
        <path
          d='M172.393,53.719C172.393,54.429 171.81,55.013 171.1,55.013C170.39,55.013 169.807,54.429 169.807,53.719C169.807,53.009 170.39,52.426 171.1,52.426C171.81,52.426 172.393,53.009 172.393,53.719Z'
          style={{ fill: 'white', fillRule: 'nonzero' }}
        />
        <path
          d='M142.002,36.665C142.002,37.241 141.528,37.716 140.951,37.716C140.375,37.716 139.901,37.241 139.901,36.665C139.901,36.089 140.375,35.614 140.951,35.614C141.528,35.614 142.002,36.089 142.002,36.665Z'
          style={{ fill: 'white', fillRule: 'nonzero' }}
        />
        <path
          d='M153.318,42.646C153.318,43.222 152.843,43.697 152.267,43.697C151.691,43.697 151.217,43.222 151.217,42.646C151.217,42.07 151.691,41.595 152.267,41.595C152.843,41.595 153.318,42.07 153.318,42.646Z'
          style={{ fill: 'white', fillRule: 'nonzero' }}
        />
        <path
          d='M138.607,25.915C138.607,26.624 138.023,27.208 137.314,27.208C136.604,27.208 136.021,26.624 136.021,25.915C136.021,25.206 136.604,24.622 137.314,24.622C138.023,24.622 138.607,25.206 138.607,25.915Z'
          style={{ fill: 'white', fillRule: 'nonzero' }}
        />
        <path
          d='M121.472,36.342C121.472,36.919 120.998,37.393 120.422,37.393C119.845,37.393 119.371,36.919 119.371,36.342C119.371,35.766 119.845,35.291 120.422,35.291C120.998,35.291 121.472,35.766 121.472,36.342Z'
          style={{ fill: 'white', fillRule: 'nonzero' }}
        />
        <path
          d='M124.544,38.444C124.544,39.02 124.069,39.494 123.493,39.494C122.917,39.494 122.442,39.02 122.442,38.444C122.442,37.867 122.917,37.393 123.493,37.393C124.069,37.393 124.544,37.867 124.544,38.444Z'
          style={{ fill: 'white', fillRule: 'nonzero' }}
        />
        <path
          d='M127.615,40.545C127.615,41.121 127.14,41.595 126.564,41.595C125.987,41.595 125.513,41.121 125.513,40.545C125.513,39.968 125.987,39.494 126.564,39.494C127.14,39.494 127.615,39.968 127.615,40.545Z'
          style={{ fill: 'white', fillRule: 'nonzero' }}
        />
        <path
          d='M122.927,44.586C122.927,45.162 122.453,45.637 121.877,45.637C121.3,45.637 120.826,45.162 120.826,44.586C120.826,44.009 121.3,43.535 121.877,43.535C122.453,43.535 122.927,44.009 122.927,44.586Z'
          style={{ fill: 'white', fillRule: 'nonzero' }}
        />
        <path
          d='M98.517,61.721C98.517,62.298 98.043,62.772 97.466,62.772C96.89,62.772 96.415,62.298 96.415,61.721C96.415,61.145 96.89,60.671 97.466,60.671C98.043,60.671 98.517,61.145 98.517,61.721Z'
          style={{ fill: 'white', fillRule: 'nonzero' }}
        />
        <path
          d='M103.044,67.218C103.044,67.794 102.569,68.268 101.993,68.268C101.417,68.268 100.942,67.794 100.942,67.218C100.942,66.641 101.417,66.167 101.993,66.167C102.569,66.167 103.044,66.641 103.044,67.218Z'
          style={{ fill: 'white', fillRule: 'nonzero' }}
        />
        <path
          d='M99.487,155.965C99.487,156.541 99.012,157.016 98.436,157.016C97.86,157.016 97.385,156.541 97.385,155.965C97.385,155.389 97.86,154.914 98.436,154.914C99.012,154.914 99.487,155.389 99.487,155.965Z'
          style={{ fill: 'white', fillRule: 'nonzero' }}
        />
        <path
          d='M100.134,180.454C100.134,181.165 99.549,181.749 98.84,181.749C98.131,181.749 97.547,181.165 97.547,180.454C97.547,179.746 98.131,179.163 98.84,179.163C99.549,179.163 100.134,179.746 100.134,180.454Z'
          style={{ fill: 'white', fillRule: 'nonzero' }}
        />
        <path
          d='M86.231,192.985C86.231,193.561 85.756,194.036 85.18,194.036C84.604,194.036 84.13,193.561 84.13,192.985C84.13,192.407 84.604,191.934 85.18,191.934C85.756,191.934 86.231,192.407 86.231,192.985Z'
          style={{ fill: 'white', fillRule: 'nonzero' }}
        />
        <path
          d='M69.58,198.077C69.58,198.786 68.996,199.37 68.287,199.37C67.578,199.37 66.994,198.786 66.994,198.077C66.994,197.366 67.578,196.782 68.287,196.782C68.996,196.782 69.58,197.366 69.58,198.077Z'
          style={{ fill: 'white', fillRule: 'nonzero' }}
        />
        <path
          d='M66.186,186.76C66.186,187.469 65.602,188.053 64.893,188.053C64.183,188.053 63.599,187.469 63.599,186.76C63.599,186.051 64.183,185.467 64.893,185.467C65.602,185.467 66.186,186.051 66.186,186.76Z'
          style={{ fill: 'white', fillRule: 'nonzero' }}
        />
        <path
          d='M64.246,193.954C64.246,194.53 63.772,195.004 63.195,195.004C62.619,195.004 62.145,194.53 62.145,193.954C62.145,193.377 62.619,192.903 63.195,192.903C63.772,192.903 64.246,193.377 64.246,193.954Z'
          style={{ fill: 'white', fillRule: 'nonzero' }}
        />
        <path
          d='M51.798,187.973C51.798,188.549 51.324,189.024 50.747,189.024C50.171,189.024 49.697,188.549 49.697,187.973C49.697,187.397 50.171,186.922 50.747,186.922C51.324,186.922 51.798,187.397 51.798,187.973Z'
          style={{ fill: 'white', fillRule: 'nonzero' }}
        />
        <path
          d='M95.283,208.179C95.283,208.755 94.809,209.23 94.233,209.23C93.656,209.23 93.182,208.755 93.182,208.179C93.182,207.603 93.656,207.128 94.233,207.128C94.809,207.128 95.283,207.603 95.283,208.179Z'
          style={{ fill: 'white', fillRule: 'nonzero' }}
        />
        <path
          d='M108.378,198.561C108.378,199.27 107.794,199.854 107.085,199.854C106.376,199.854 105.792,199.27 105.792,198.561C105.792,197.852 106.376,197.268 107.085,197.268C107.794,197.268 108.378,197.852 108.378,198.561Z'
          style={{ fill: 'white', fillRule: 'nonzero' }}
        />
        <path
          d='M109.833,210.927C109.833,211.503 109.359,211.978 108.782,211.978C108.206,211.978 107.731,211.503 107.731,210.927C107.731,210.351 108.206,209.878 108.782,209.878C109.359,209.878 109.833,210.351 109.833,210.927Z'
          style={{ fill: 'white', fillRule: 'nonzero' }}
        />
        <path
          d='M163.664,199.127C163.664,199.704 163.19,200.178 162.613,200.178C162.037,200.178 161.563,199.704 161.563,199.127C161.563,198.549 162.037,198.077 162.613,198.077C163.19,198.077 163.664,198.549 163.664,199.127Z'
          style={{ fill: 'white', fillRule: 'nonzero' }}
        />
        <path
          d='M138.607,208.017C138.607,208.595 138.132,209.068 137.556,209.068C136.98,209.068 136.506,208.595 136.506,208.017C136.506,207.441 136.98,206.968 137.556,206.968C138.132,206.968 138.607,207.441 138.607,208.017Z'
          style={{ fill: 'white', fillRule: 'nonzero' }}
        />
        <path
          d='M206.017,220.789C206.017,221.365 205.543,221.839 204.966,221.839C204.39,221.839 203.915,221.365 203.915,220.789C203.915,220.212 204.39,219.738 204.966,219.738C205.543,219.738 206.017,220.212 206.017,220.789Z'
          style={{ fill: 'white', fillRule: 'nonzero' }}
        />
        <path
          d='M211.513,171.079C211.513,171.788 210.929,172.372 210.22,172.372C209.511,172.372 208.927,171.788 208.927,171.079C208.927,170.371 209.511,169.787 210.22,169.787C210.929,169.787 211.513,170.371 211.513,171.079Z'
          style={{ fill: 'white', fillRule: 'nonzero' }}
        />
        <path
          d='M201.167,178.111C201.167,178.689 200.693,179.163 200.116,179.163C199.54,179.163 199.066,178.689 199.066,178.111C199.066,177.537 199.54,177.062 200.116,177.062C200.693,177.062 201.167,177.537 201.167,178.111Z'
          style={{ fill: 'white', fillRule: 'nonzero' }}
        />
        <path
          d='M211.352,180.132C211.352,180.841 210.768,181.425 210.059,181.425C209.35,181.425 208.766,180.841 208.766,180.132C208.766,179.423 209.35,178.839 210.059,178.839C210.768,178.839 211.352,179.423 211.352,180.132Z'
          style={{ fill: 'white', fillRule: 'nonzero' }}
        />
        <path
          d='M201.167,192.661C201.167,193.237 200.693,193.711 200.116,193.711C199.54,193.711 199.066,193.237 199.066,192.661C199.066,192.084 199.54,191.61 200.116,191.61C200.693,191.61 201.167,192.084 201.167,192.661Z'
          style={{ fill: 'white', fillRule: 'nonzero' }}
        />
        <path
          d='M227.517,164.048C227.517,164.624 227.043,165.099 226.467,165.099C225.889,165.099 225.414,164.624 225.414,164.048C225.414,163.472 225.889,162.997 226.467,162.997C227.043,162.997 227.517,163.472 227.517,164.048Z'
          style={{ fill: 'white', fillRule: 'nonzero' }}
        />
        <path
          d='M230.104,170.433C230.104,171.144 229.52,171.726 228.811,171.726C228.101,171.726 227.517,171.144 227.517,170.433C227.517,169.724 228.101,169.14 228.811,169.14C229.52,169.14 230.104,169.724 230.104,170.433Z'
          style={{ fill: 'white', fillRule: 'nonzero' }}
        />
        <path
          d='M248.209,178.031C248.209,178.74 247.625,179.324 246.915,179.324C246.205,179.324 245.621,178.74 245.621,178.031C245.621,177.322 246.205,176.738 246.915,176.738C247.625,176.738 248.209,177.322 248.209,178.031Z'
          style={{ fill: 'white', fillRule: 'nonzero' }}
        />
        <path
          d='M229.781,191.53C229.781,192.106 229.306,192.58 228.73,192.58C228.154,192.58 227.679,192.106 227.679,191.53C227.679,190.954 228.154,190.479 228.73,190.479C229.306,190.479 229.781,190.954 229.781,191.53Z'
          style={{ fill: 'white', fillRule: 'nonzero' }}
        />
        <path
          d='M241.419,180.537C241.419,181.113 240.945,181.587 240.368,181.587C239.792,181.587 239.318,181.113 239.318,180.537C239.318,179.96 239.792,179.486 240.368,179.486C240.945,179.486 241.419,179.96 241.419,180.537Z'
          style={{ fill: 'white', fillRule: 'nonzero' }}
        />
        <path
          d='M239.318,197.51C239.318,198.086 238.843,198.561 238.267,198.561C237.691,198.561 237.216,198.086 237.216,197.51C237.216,196.934 237.691,196.459 238.267,196.459C238.843,196.459 239.318,196.934 239.318,197.51Z'
          style={{ fill: 'white', fillRule: 'nonzero' }}
        />
        <path
          d='M168.848,24.697C169.026,24.04 169.558,23.528 170.227,23.379L170.594,20.612L171.137,23.405C171.785,23.59 172.287,24.12 172.434,24.783L175.235,25.18L172.408,25.678C172.23,26.317 171.717,26.815 171.07,26.973L170.594,29.82L170.114,26.952C169.485,26.77 168.993,26.26 168.837,25.619L166.135,25.18L168.848,24.697Z'
          style={{ fill: 'white', fillRule: 'nonzero' }}
        />
        <path
          d='M96.37,222.996C96.548,222.339 97.081,221.826 97.749,221.677L98.117,218.912L98.661,221.705C99.308,221.888 99.811,222.417 99.956,223.081L102.758,223.478L99.931,223.976C99.753,224.615 99.239,225.113 98.592,225.271L98.117,228.12L97.637,225.251C97.007,225.068 96.516,224.558 96.359,223.917L93.657,223.478L96.37,222.996Z'
          style={{ fill: 'white', fillRule: 'nonzero' }}
        />
        <path
          d='M229.659,197.43C229.837,196.772 230.368,196.26 231.037,196.112L231.405,193.344L231.949,196.137C232.596,196.321 233.099,196.852 233.244,197.516L236.045,197.913L233.219,198.411C233.04,199.049 232.528,199.547 231.88,199.705L231.405,202.553L230.925,199.684C230.295,199.502 229.805,198.991 229.648,198.352L226.947,197.913L229.659,197.43Z'
          style={{ fill: 'white', fillRule: 'nonzero' }}
        />
        <path
          d='M240.211,168.919C240.388,168.261 240.92,167.749 241.588,167.601L241.956,164.833L242.5,167.626C243.148,167.812 243.651,168.341 243.796,169.005L246.597,169.402L243.771,169.9C243.592,170.538 243.079,171.037 242.433,171.195L241.956,174.042L241.477,171.173C240.847,170.992 240.357,170.482 240.2,169.841L237.497,169.402L240.211,168.919Z'
          style={{ fill: 'white', fillRule: 'nonzero' }}
        />
        <path
          d='M109.303,63.606C109.481,62.948 110.013,62.437 110.681,62.287L111.049,59.521L111.593,62.314C112.241,62.498 112.743,63.028 112.889,63.692L115.69,64.089L112.863,64.586C112.685,65.225 112.172,65.724 111.525,65.882L111.049,68.729L110.569,65.861C109.94,65.678 109.449,65.168 109.293,64.528L106.59,64.089L109.303,63.606Z'
          style={{ fill: 'white', fillRule: 'nonzero' }}
        />
        <path
          d='M100.756,123.628C110.286,140.277 121.393,152.461 130.341,164.038C138.283,174.314 153.757,179.458 148.307,184.336C142.164,189.832 127.292,165.583 127.292,165.583C117.408,153.664 107.949,141.02 98.299,124.483L100.756,123.628Z'
          style={{ fill: 'white', fillRule: 'nonzero' }}
        />
        <path
          d='M0,167.318C46.046,137.076 126.409,117.552 192.597,91.275C242.884,71.31 287.601,46.248 311.639,11.739C250.721,135.827 81.066,121.139 3.312,168.474L0,167.318Z'
          style={{ fill: 'white', fillRule: 'nonzero' }}
        />
        <path
          d='M107.05,105.788L130.11,105.788L146.644,147.424L152.155,152.955L122.134,152.955L127.645,147.424L125.687,142.344L109.117,142.344L107.413,148.153L111.365,152.955L95.701,152.955L101.213,147.424L111.837,110.604L107.05,105.788ZM122.751,135.054L116.078,118.147L111.184,135.054L122.751,135.054Z'
          style={{ fill: 'white', fillRule: 'nonzero' }}
        />
        <path
          d='M214.184,105.788L238.118,105.788L254.653,147.424L260.164,152.955L230.143,152.955L235.654,147.424L233.696,142.344L217.125,142.344L215.421,148.153L219.373,152.955L203.71,152.955L209.222,147.424L219.845,110.604L214.184,105.788ZM230.759,135.054L224.087,118.147L219.192,135.054L230.759,135.054Z'
          style={{ fill: 'white', fillRule: 'nonzero' }}
        />
        <path
          d='M75.758,135.163L75.758,110.604L70.972,105.788L86.925,105.788L82.14,110.604L82.14,147.424L87.651,152.955L66.693,152.955L48.637,122.411L48.637,147.424L53.277,152.955L36.6,152.955L42.111,147.424L42.111,110.604L37.325,105.788L58.21,105.788L75.758,135.163Z'
          style={{ fill: 'white', fillRule: 'nonzero' }}
        />
        <path
          d='M196.553,117.885L196.063,103.2L191.12,106.612C184.156,104.177 176.051,104.269 170.364,106.96C165.071,109.465 159.36,114.754 160.055,122.27C160.752,129.786 166.577,132.695 171.826,135.074C178.582,138.136 185.936,139.171 185.827,143.217C185.757,145.791 181.485,147.637 178.165,147.532C175.135,147.436 165.28,146.696 160.125,140.573L161.101,156.02L166.604,151.985C173.289,154.35 181.392,155.342 188.889,152.897C196.341,150.469 204.174,144.233 199.827,133.335C197.956,128.646 192.804,125.755 189.731,124.497C186.917,123.345 178.333,119.97 175.936,118.72C173.136,117.262 173.448,112.632 180.185,111.692C186.288,110.841 194.742,115.728 196.553,117.885Z'
          style={{ fill: 'white', fillRule: 'nonzero' }}
        />
        <path
          d='M35.658,239.727C128.131,145.348 265.971,101.743 311.639,11.739C284.138,99.243 136.85,147.504 35.408,244.165L35.658,239.727Z'
          style={{ fill: 'white', fillRule: 'nonzero' }}
        />
        <path
          d='M98.299,124.483C97.216,122.627 96.131,120.722 95.042,118.763C70.388,74.411 73.944,29.148 118.563,48.87C156.431,65.609 192.6,112.561 207.146,145.907C213.887,161.356 231.96,221.996 173.764,197.424L173.65,197.069C228.613,219.701 219.111,162.351 189.691,118.38C173.042,93.5 151.331,72.775 137.637,62.772C92.05,29.471 64.246,49.84 96.253,115.147C97.702,118.102 99.208,120.925 100.756,123.628L98.299,124.483Z'
          style={{ fill: 'white', fillRule: 'nonzero' }}
        />
      </g>
    </svg>
  );
}
